import React from "react";
import PropTypes from "prop-types";
import Header from "../components/header/header";
import Footer from "../components/footer/footer";
import CookieConsent from 'react-cookie-consent';
import '../scss/style.scss';


const Layout = ({ children }) => {
  return (
    
    <div className="main-wrapper">
      <CookieConsent enableDeclineButton flipButtons style={{ background: "#E88960" }} buttonStyle={{ background: "#91CFF0" }}
          location="bottom"
          buttonText="J'accepte"
          declineButtonText="Je refuse"
          cookieName="gatsby-gdpr-google-analytics">
Ce site Web utilise des cookies posés par Google Analytics, un outil de suivi de trafic qui nous aide à vous apporter une meilleure expérience utilisateur. Vous pouvez accepter ou refuser ces cookies.
</CookieConsent>
        <Header />
        <main>{children}</main>
        <Footer />
    </div>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
