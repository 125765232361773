import React, {Fragment, useState, useEffect} from "react";
import PropTypes from "prop-types";
import {useStaticQuery, graphql , Link} from 'gatsby';
import Img from "gatsby-image";
import Scrollspy from 'react-scrollspy';

// Start Header Area 
const Header = () => {
    const headerQuery = useStaticQuery(graphql`
        query headerQuery {
            allMenuJson {
                nodes {
                    title
                    path
                }
            },
            file(relativePath: {eq: "images/logo/logo0.png"}) {
                childImageSharp {
                  fixed(width: 270) {
                    ...GatsbyImageSharpFixed
                  }
                }
            }
        }
    `);
    const [isOverlayOpen, setIsOverlayOpen] = useState(false);

    useEffect(() => {
        if(!isOverlayOpen){
          document.querySelector('.trigger-popup-menu').classList.remove('overlay-wrapper-open');
          document.querySelector('.hambergur-menu').classList.remove('hambergur-menu-open');
        }
    });

    const onMenuToggleClick = () => {
        setIsOverlayOpen(prevState => !prevState)
        document.querySelector('.trigger-popup-menu').classList.toggle('overlay-wrapper-open');
        document.querySelector('.hambergur-menu').classList.toggle('hambergur-menu-open');
    }
    const arloLogo = headerQuery.file.childImageSharp.fixed;

    return (
        <Fragment>
            <header className="rn-header header-default header-transparent bg-color-black">
                <div className="header-inner">
                    {/* Header Logo  */}
                    <div className="header-left">
                        <div className="logo">
                            <Link to="/">
                                <Img fixed={arloLogo}  />
                            </Link>
                        </div>
                    </div>
                    {/* Main Menu  */}
                    <div className="header-right" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick} role="button" tabIndex="0">
                        <div className="hambergur-menu">
                            <div className="hamburger-box">
                                <div className="hamburger-inner"></div>
                            </div>
                        </div>
                    </div>
                    {/* Start Social Area  */}
                </div>
            </header>
            <div className="trigger-popup-menu">
                <div className="menu_full">
                    <div className="menu_wrapper">
                        <Scrollspy className="popup_mainmenu" items={['home','about', 'news', 'contact']} currentClassName="is-current" offset={-200}>
                            <li className="withBorder">
                                <Link className="menu-hover-link" to="/" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick}>
                                    <span className="hover-item">
                                        <span data-text="Accueil">Accueil</span>
                                    </span>
                                </Link>
                            </li>
                            <li className="withBorder">

                                <Link className="menu-hover-link" to="/seo-services/" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick}>
                                    <span className="hover-item">
                                        <span data-text="Coaching SEO">Coaching SEO</span>
                                    </span>
                                </Link>
                            </li>
                            <li className="withBorder">
                                <Link className="menu-hover-link" to="/dev-services/" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick}>
                                    <span className="hover-item">
                                        <span data-text="Développement Web">Développement Web</span>
                                    </span>
                                </Link>
                            </li>
                            <li className="withBorder">
                                <Link className="menu-hover-link" to="/blog/" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick}>
                                    <span className="hover-item">
                                        <span data-text="Blog">Blog</span>
                                    </span>
                                </Link>
                            </li>
                            <li className="withBorder">
                                <Link className="menu-hover-link" to="/contact/" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick}>
                                    <span className="hover-item">
                                        <span data-text="Contact">Contact</span>
                                    </span>
                                </Link>
                            </li>
                        </Scrollspy>
                    </div>
                    <div className="trigger_closer" onClick={onMenuToggleClick} onKeyDown={onMenuToggleClick} role="button" tabIndex="0">
                        <span className="text">Fermer</span>
                        <span className="icon"></span>
                    </div>
                </div>
            </div>
        </Fragment>
    )
}
// End Header Area 

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header;
